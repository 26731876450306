import Middlewares from '../Middlewares/Index';
import BranchTabListOrders from '../views/Branch/Orders/Tab-Order';
import BranchTabListOrdersInbound from '../views/Branch/inbound/Tab-Order';
import BranchListOrderDetailOutbound from '../views/Branch/Orders/List-OrderDetail';
import BranchListOrderDetailInbound from '../views/Branch/inbound/List-OrderDetail';
import AccountTabOrders from '../views/Accounting/Tab-Order';
import TabListDistributionCenterAllShipment from '../views/DistributionCenter/Shipment-Orders/Tab-Order';
import ListDistributionCenterShipmentDetail from '../views/DistributionCenter/Shipment-Orders/List-Order-Detail';
import ListDistributionCenterShipmenBag from '../views/DistributionCenter/Shipment-Orders/List-Shipment-Bag';
import ListDistributionCenterShipmenBagDetail
    from '../views/DistributionCenter/Shipment-Orders/List-Shipment-Bag-Detail';

import ListDistributionCenterShipmenBagReusable
    from '@/views/DistributionCenter/Shipment-Orders/ParcelBagReusable/List-Shipment-Bag';
import ListDistributionCenterShipmenBagReusableDetail
    from '@/views/DistributionCenter/Shipment-Orders/ParcelBagReusable/List-Shipment-Bag-Detail';
import ListDistributionCenterShipmenBagReusableRecyclingDetail
    from '@/views/DistributionCenter/Shipment-Orders/ParcelBagReusable/List-Shipment-Bag-Recycling-Detail';

import ViewCODDetail from '../views/Accounting/Details/COD-Detail';
import ViewOriginFreightDetail from '../views/Accounting/Details/Origin-Detail';
import ViewCDCtDetail from '../views/Accounting/Details/CDC-Detail';
import ViewCodBranchDetail from '../views/Accounting/Details/COD-Branch-Detail';
import ViewCompanyDetail from '../views/Accounting/Details/Company-Detail';

// List Shipment Company
import TabShipments from '../views/Accounting/ShipmentCompany/Tab-shipments';
import TabSolveShipment from "@views/Accounting/SolveShipments/Tab-Solve-Shipment";
import AdminTabSolveShipment from "@views/SuperAdmin/Shipments/SolveShipments/Tab-Solve-Shipment";
import TabInvoiceLocal from "@views/InvoiceLocalBranch/Tab-Invoice-local";
import TabInsertInvoiceLocal from "@views/InvoiceLocalBranch/ListForInsert/Tab-insert-invoice-local";
import TabInsertInvoiceFranchise from "@views/InvoiceFranchiseBranch/ListForInsert/Tab-insert-invoice-Franchise";
import TabInvoiceFranchise from "@views/InvoiceFranchiseBranch/Tab-Invoice-Franchise";
import TabInvoiceBranch from "@views/Branch/InvoiceBranch/Tab-Invoice-Branch";
import listInvoiceBranchDetail from "@views/Branch/InvoiceBranch/listInvoiceBranchDetail";
import exportShipmentBranchQty from "@views/ShipmentTax/exportShipmentBranchQty";
import MainTabPendingCustomerCodFeature from "@views/Accounting/Customer/COD/MainTabPendingCustomerCodFeature.vue";
import MainTabSuccessCustomerCodFeature from "@views/Accounting/Customer/COD/MainTabSuccessCustomerCodFeature.vue";
import exportShipmentBranchPostOfficeQty from "@views/ShipmentTax/exportShipmentBranchPostOfficeQty.vue";

const branch_accounting = [
    {
        path: '/branch/tab-list-order',
        name: 'branch.tab.list.order',
        component: BranchTabListOrders,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/branch/tab-list-order-inbound',
        name: 'branch.tab.list.order.inbound',
        component: BranchTabListOrdersInbound,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/branch/list-order-detail/:id',
        name: 'branch.list.order.detail.outbound',
        component: BranchListOrderDetailOutbound,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/branch/list-order-detail-inbound/:id',
        name: 'branch.list.order.detail.inbound',
        component: BranchListOrderDetailInbound,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/account/tab-list-order',
        name: 'account.tab.list.order',
        component: AccountTabOrders,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/accounting/invoice/cod/detail/:id',
        name: 'accounting.invoice.cod.detail',
        component: ViewCODDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/accounting/invoice/origin/freight/detail/:id',
        name: 'accounting.invoice.origin.freight.detail',
        component: ViewOriginFreightDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/accounting/invoice/cdc/detail/:id',
        name: 'accounting.invoice.cdc.detail',
        component: ViewCDCtDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/accounting/invoice/cod/branch/detail/:id',
        name: 'accounting.invoice.cod.branch.detail',
        component: ViewCodBranchDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/accounting/invoice/company/detail/:id',
        name: 'accounting.invoice.company.detail',
        component: ViewCompanyDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/accounting/pending-customer-cod-invoice-payment',
        name: 'accounting.pending.customer.cod.invoice.payment',
        component: MainTabPendingCustomerCodFeature,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/accounting/success-customer-cod-invoice-payment',
        name: 'accounting.success.customer.cod.invoice.payment',
        component: MainTabSuccessCustomerCodFeature,
        meta: {
            middleware: [Middlewares.auth],
        }
    },


    // Distribution Center All List Shipments
    {
        path: '/distribution/center/tab-all-shipment',
        name: 'distribution.center.tab.all.shipments',
        component: TabListDistributionCenterAllShipment,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/distribution/center/order/detail/:id',
        name: 'distribution.center.list.order.detail',
        component: ListDistributionCenterShipmentDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/distribution/center/shipment/bag',
        name: 'distribution.center.shipment.bag',
        component: ListDistributionCenterShipmenBag,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/distribution/center/shipment/bag/detail/:id',
        name: 'distribution.center.shipment.bag.detail',
        component: ListDistributionCenterShipmenBagDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    {
        path: '/distribution/center/shipment/bag/reusable',
        name: 'distribution.center.shipment.bag.reusable',
        component: ListDistributionCenterShipmenBagReusable,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/distribution/center/shipment/bag/reusable/detail/:bagNumber',
        name: 'distribution.center.shipment.bag.reusable.detail',
        component: ListDistributionCenterShipmenBagReusableDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/distribution/center/shipment/bag/reusable/detail/:id/recycling',
        name: 'distribution.center.shipment.bag.reusable.recycling.detail',
        component: ListDistributionCenterShipmenBagReusableRecyclingDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

    // List shipment for company
    {
        path: '/account/tab-list-shipment',
        name: 'account.tab.list.shipment',
        component: TabShipments,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    ////tab solve shipment for accountant
    {
        path: '/account/tab-solve-shipments',
        name: 'account.tab.solve.shipments',
        component: TabSolveShipment,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab-solve-shipments',
        name: 'tab.solve.shipments',
        component: AdminTabSolveShipment,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab-invoice-local',
        name: 'tab.invoice.local',
        component: TabInvoiceLocal,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab-insert-invoice-local',
        name: 'tab.insert.invoice.local',
        component: TabInsertInvoiceLocal,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab-invoice-franchise',
        name: 'tab.invoice.franchise',
        component: TabInvoiceFranchise,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab-insert-invoice-franchise',
        name: 'tab.insert.invoice.franchise',
        component: TabInsertInvoiceFranchise,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/tab-invoice-branch',
        name: 'tab.invoice.branch',
        component: TabInvoiceBranch,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/list/branch/detail/:type/:id',
        name: 'list.invoice.branch.detail',
        component: listInvoiceBranchDetail,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/shipment/branch/quantities',
        name: 'shipment.branch.quantities',
        component: exportShipmentBranchQty,
        meta: {
            middleware: [Middlewares.auth],
        }
    },
    {
        path: '/shipment/branch/post-office/quantities',
        name: 'shipment.branch.post.office.quantities',
        component: exportShipmentBranchPostOfficeQty,
        meta: {
            middleware: [Middlewares.auth],
        }
    },

]
export default branch_accounting
