export default {
    data: () => ({
        btnLoading: false,
        server_errors: {
            name: '',
            code: '',
            discount: '',
            total_amount: '',
            total_amount_per_day: '',
            amount_per_user: '',
            valid_date: '',
            discount_type: '',
            minimum_amount: '',
            maximum_discount: '',
            start_weight: '',
            end_weight: '',
            shipment_pay_type: '',
        },
        couponTypes: [
            {type: 'percent', name: 'ເປີເຊັນ'},
            {type: 'cash', name: 'ເງີນສົດ'}
        ],
        shipmentPayType: [
            {type: 'origin', name: 'ຕົ້ນທາງ'},
            {type: 'destination', name: 'ປາຍທາງ'},
            {type: 'origin_destination', name: 'ຕົ້ນທາງ-ປາຍທາງ'},
            {type: 'origin_cod', name: 'ຕົ້ນທາງ COD'},
            {type: 'destination_cod', name: 'ປາຍທາງ COD'},
            {type: 'origin_destination_cod', name: 'ຕົ້ນທາງ-ປາຍທາງ COD'},
        ],
        selectedShipmentPayType: {type: 'origin_destination', name: 'ຕົ້ນທາງ-ປາຍທາງ'},
        selectedCouponType: {type: 'cash', name: 'ເງີນສົດ'},
        Discount_Cash: 0,
        Discount_Percent: 0,
    }),
    methods: {
        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.EditCoupons();
                }
            });
        },
        EditCoupons() {
            this.btnLoading = true;
            if (this.filterCoupon.discount_type == 'cash') {
                this.filterCoupon.discount = this.Discount_Cash;
            } else {
                this.filterCoupon.discount = this.Discount_Percent;
            }
            this.filterCoupon.shipment_pay_type = this.selectedShipmentPayType.type;
            this.$axios.put('edit-coupons', this.filterCoupon).then((res) => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error => {
                this.btnLoading = false;
                if (error.response.status == 422) {
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        },
        BtnLoading() {
            return this.$vs.loading({
                target: this.$refs.button,
                scale: '0.6',
                background: 'primary',
                opacity: 1,
                color: '#fff'
            });
        }
    },
    watch: {
        'filterCoupon.name': function () {
            this.server_errors.name = '';
        },
        'filterCoupon.code': function () {
            this.server_errors.code = '';
        },
        'filterCoupon.discount': function () {
            this.server_errors.discount = '';
        },
        'filterCoupon.total_amount': function () {
            this.server_errors.total_amount = '';
        },
        'filterCoupon.valid_date': function () {
            this.server_errors.valid_date = '';
        },
        'filterCoupon.total_amount_per_day': function () {
            this.server_errors.total_amount_per_day = '';
        },
        'filterCoupon.minimum_amount': function () {
            this.server_errors.minimum_amount = '';
        }, 'filterCoupon.maximum_discount': function () {
            this.server_errors.maximum_discount = '';
        },
        'filterCoupon.start_weight': function () {
            this.server_errors.start_weight = '';
        },
        'filterCoupon.end_weight': function () {
            this.server_errors.end_weight = '';
        },
        'filterCoupon.shipment_pay_type': {
            handler: function () {
                const matched =
                    this.shipmentPayType.filter((item) => item.type === this.filterCoupon.shipment_pay_type);
                if (matched.length > 0) {
                    this.selectedShipmentPayType = matched[0];
                }
            },
            immediate: true
        }
    },
}

