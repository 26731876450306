export default {
    data: () => ({
        btnLoading: false,
        server_errors: {
            name: '',
            code: '',
            discount: '',
            total_amount: '',
            total_amount_per_day: '',
            amount_per_user: '',
            valid_date: '',
            discount_type: '',
            minimum_amount: '',
            maximum_discount: '',
            start_weight: '',
            end_weight: '',
            shipment_pay_type: '',
        },
        value: {
            total_amount: '135000',
            total_amount_per_day: 1,
            amount_per_user: 1,
            is_only_new_customer: false,
            valid_date: new Date(),
            minimum_amount: null,
            maximum_discount: null,
            start_weight: null,
            end_weight: null,
            shipment_pay_type: null,
        },
        couponTypes: [
            {type: 'cash', name: 'ເງີນສົດ'},
            {type: 'percent', name: 'ເປີເຊັນ'},
        ],
        shipmentPayType: [
            {type: 'origin', name: 'ຕົ້ນທາງ'},
            {type: 'destination', name: 'ປາຍທາງ'},
            {type: 'origin_destination', name: 'ຕົ້ນທາງ-ປາຍທາງ'},
            {type: 'origin_cod', name: 'ຕົ້ນທາງ COD'},
            {type: 'destination_cod', name: 'ປາຍທາງ COD'},
            {type: 'origin_destination_cod', name: 'ຕົ້ນທາງ-ປາຍທາງ COD'},
        ],
        selectedCouponType: {type: 'cash', name: 'ເງີນສົດ'},
        selectedShipmentPayType: {type: 'origin_destination', name: 'ຕົ້ນທາງ-ປາຍທາງ'},
    }),
    methods: {
        ValidateForm() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.SaveCoupons();
                }
            });
        },
        SaveCoupons() {
            this.btnLoading = true;
            this.value.couponType = this.selectedCouponType.type;
            this.value.shipment_pay_type = this.selectedShipmentPayType.type;
            this.$axios.post('add-coupons', this.value).then((res) => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.$emit('close');
                        this.$emit('success');
                        this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'dark', 3000);
                    }, 300);
                }
            }).catch(error => {
                this.btnLoading = false;
                if (error.response.status == 422) {
                    var obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
                    for (let [key, value] of Object.entries(obj)) {
                        this.server_errors[key] = value[0];
                    }
                }
            });
        }
    },
    watch: {
        'value.name': function () {
            this.server_errors.name = '';
        },
        'value.code': function () {
            this.server_errors.code = '';
        },
        'value.discount': function () {
            this.server_errors.discount = '';
        },
        'value.total_amount': function () {
            this.server_errors.total_amount = '';
        },
        'value.valid_date': function () {
            this.server_errors.valid_date = '';
        },
        'value.total_amount_per_day': function () {
            this.server_errors.total_amount_per_day = '';
        },
        'value.minimum_amount': function () {
            this.server_errors.minimum_amount = '';
        }, 'value.maximum_discount': function () {
            this.server_errors.maximum_discount = '';
        },
        'value.start_weight': function () {
            this.server_errors.start_weight = '';
        },
        'value.end_weight': function () {
            this.server_errors.end_weight = '';
        },
        'selectedShipmentPayType.type': function () {
            this.server_errors.shipment_pay_type = '';
        }
    },
}

