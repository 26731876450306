<template>
  <div class="container"
       :class="{'sidebar-hidden': isMobile && !showSidebar, 'sidebar-visible': isMobile && showSidebar}">
    <!-- Mobile sidebar toggle button -->
    <div class="sidebar-toggle" v-if="isMobile" @click="toggleSidebar" style="z-index: 1050;">
      <i class="fas" :class="{'fa-comments': !showSidebar, 'fa-times': showSidebar}"></i>
    </div>

    <!-- Main content area - adjust column width to prevent overlap -->
    <div class="columns is-mobile main-content-area">
      <div class="column is-12" :class="{'is-9-desktop': !isMobile, 'chat-area': true}">
        <!--Chat Message-->
        <div class="card scrollable" id="body-chat">
          <div class="card-content" v-if="chatId">
            <div class="columns is-mobile" v-for="(message,index) in messages" :key="index">
              <div class="column is-12">
                <div v-if="!message.image_url" class="is-receiver"
                     :class="{'is-sender': checkSender(message.sender_id)}">
                  <div v-if="!message.image_url" class="is-receiver-background"
                       :class="{'is-sender-background':checkSender(message.sender_id)}">
                    <p v-if="message.messages" v-html="urlDetect(message.messages)">
                    </p>
                  </div>
                  <p class="chat-date">{{ moment(message.timeToken).format('DD/MM/YYYY HH:mm') }}</p>
                </div>
                <div v-if="message.image_url">
                  <div class="is-receiver-file" :class="{'is-sender-file':checkSender(message.sender_id)}">
                    <img :src="message.image_url" alt="image" class="responsive-image"
                         @click="showImage(message.image_url)">
                  </div>
                  <p class="chat-date-file" :class="{'is-sender-file':checkSender(message.sender_id)}">
                    {{ moment(message.timeToken).format('DD/MM/YYYY HH:mm') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Chat Sidebar - Modified to avoid overlap -->
    <vs-sidebar
        right
        v-model="active"
        :open="!isMobile || showSidebar"
        style="z-index: 1040;border-text-outline: none;top: 52px;"
        class="sidebar-responsive"
        v-show="!isMobile || showSidebar"
        ref="sidebar">
      <template #header>
        <div style="margin-bottom: 8px; margin-top: 8px; width: 100%;">
          <vs-input icon="search" placeholder="ເບີໂທ" v-model="message" @keyup.enter="getRooms(null, true)"
                    class="width-100"/>
        </div>
      </template>

      <div class="sidebar-scrollable-content">
        <vs-sidebar-item :id="customer.receiver_name" v-for="(customer,index) in customers" :key="index"
                         style="padding: 4px; margin-left: 4px;">
          <template #icon @click="selectChat(customer, false)">
            <div class="profile mr-8" @click="selectChat(customer, false)">
              <vs-avatar circle class="mr-8" badge-color="danger" badge-position="top-right">
                <img class="image is-58x52 is-rounded image chat"
                     :src="customer.receiver_profile || defaultProfileImage"
                     @error="setDefaultProfileImage"
                     width="52" height="52">
                <i class='bx bx-bell'></i>
                <template v-if="customer.unread" #badge>
                  {{ customer.unread }}
                </template>
              </vs-avatar>
            </div>
          </template>
          <div @click="selectChat(customer,false)" :style="unreadStyle(customer)" class="sidebar-name">
            {{ customer.receiver_name }}
          </div>
          <div @click="selectChat(customer,false)" :style="unreadStyle(customer)" class="sidebar-tel">
            {{ customer.receiver_tel }}
          </div>
          <div class="chat-date sidebar-message" :style="unreadStyle(customer)">
            {{ customer.latest_message }}
          </div>
        </vs-sidebar-item>

        <!-- Add padding at the bottom for the fixed button -->
        <div class="bottom-padding" style="height: 80px;"></div>
      </div>

      <!-- Fixed Load More Button -->
      <div v-if="pagination && pagination.has_more" class="load-more-container-fixed">
        <button
            @click="loadMore"
            :disabled="isLoadingMore"
            class="load-more-button"
        >
          <span v-if="!isLoadingMore">ໂຫຼດຕື່ມ</span>
          <div v-else class="loading-spinner"></div>
        </button>
      </div>
    </vs-sidebar>

    <!--Send Message-->
    <nav class="navbar is-fixed-bottom border" v-if="chatId"
         style="z-index: 9999; background-color: white; border-top: 1px solid #eee;">
      <div class="navbar-brand message-input-container">
        <a class="send-image-btn" @click="OpenModalAdd">
          <i class="fas fa-image" style="color: #EB1D27; font-size: 22px;"></i>
        </a>

        <div class="field navbar-item message-field">
          <p class="control">
            <input
                class="input message-input"
                type="text"
                placeholder="ພິມຂໍ້ຄວາມ..."
                v-model="chat"
                @keyup.enter="sendMessage()"
            >
            <span class="icon send-btn" @click="sendMessage()" v-if="chat.trim()">
              <i class="fas fa-paper-plane" style="color: #EB1D27;"></i>
            </span>
          </p>
        </div>
      </div>
    </nav>

    <!--upload file-->
    <ModalAdd>
      <template v-slot="{ close }">
        <sendImage @close="close" :chatRoomId="chatId" :messages="messages" @success="scrollToBottom"/>
      </template>
    </ModalAdd>
  </div>
</template>

<script>
import sendImage from "@/views/Chat/Dialog/Add"

export default {
  components: {
    sendImage,
  },
  data: () => ({
    active: 'home',
    inactivityTimeout: null,
    message: '',
    chat: '',
    isLoading: false,
    isLoadingMore: false,
    show: false,
    openFile: false,
    imageUrl: '',
    file: '',
    customers: [],
    pagination: null,
    currentCursor: null,
    user: {},
    chatId: '',
    chatChannel: [], // Array of channels we're currently subscribed to
    messageChannel: [],
    messages: [],
    updateChatRoom: {},
    getRoomsInterval: null,
    defaultProfileImage: 'https://ui-avatars.com/api/?background=0D8ABC&color=fff&size=128&name=User',
    isMobile: false,
    showSidebar: false
  }),

  beforeRouteLeave(to, from, next) {
    this.unsubscribeFromChannels();
    next();
  },

  created() {
    // Load chat rooms and subscribe to channels
    this.getRooms();

    // Subscribe to the global notification channel
    this.subscribeToGlobalNotificationChannel();

    // Set up event listeners for inactivity and tab/browser close
    document.addEventListener('mousemove', this.resetInactivityTimer);
    document.addEventListener('keydown', this.resetInactivityTimer);
    window.addEventListener('beforeunload', this.unsubscribeFromChannels);

    // Start the inactivity timer
    this.resetInactivityTimer();
  },

  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
    this.$nextTick(() => {
      // Find and fix sidebar content container
      if (this.$refs.sidebar && this.$refs.sidebar.$el) {
        const sidebarContent = this.$refs.sidebar.$el.querySelector('.vs-sidebar__content');
        if (sidebarContent) {
          sidebarContent.style.overflow = 'hidden'; // Let our custom container handle scrolling
        }
      }
    });
  },

  beforeDestroy() {
    // Cleanup event listeners
    document.removeEventListener('mousemove', this.resetInactivityTimer);
    document.removeEventListener('keydown', this.resetInactivityTimer);
    window.removeEventListener('beforeunload', this.unsubscribeFromChannels);
    window.removeEventListener('resize', this.checkMobile);

    // Unsubscribe from channels
    this.unsubscribeFromChannels();

    // Clear large data
    this.messages = null;
    this.customers = null;

    // Clear timeouts and intervals
    clearTimeout(this.inactivityTimeout);
    clearInterval(this.getRoomsInterval);
  },

  methods: {
    /**
     * Check if the device is mobile
     */
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
      // Hide sidebar by default on mobile when a chat is selected
      if (this.isMobile && this.chatId) {
        this.showSidebar = false;
      }
    },

    /**
     * Toggle sidebar on mobile
     */
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },

    /**
     * Setup interval to refresh rooms periodically
     */
    setupGetRoomsInterval() {
      this.getRoomsInterval = setInterval(() => {
        this.getRooms(null, true);
      }, 5 * 60 * 1000); // Refresh every 5 minutes
    },

    /**
     * Style for unread messages
     */
    unreadStyle(customer) {
      return (customer.unread === null || customer.unread === 0) ? {color: 'lightgrey'} : {};
    },

    /**
     * Reset inactivity timer
     */
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimeout);
      clearInterval(this.getRoomsInterval);

      // Set timeout to unsubscribe after 5 minutes of inactivity
      this.inactivityTimeout = setTimeout(() => {
        this.unsubscribeFromChannels();
      }, 5 * 60 * 1000);

      // Set interval to refresh rooms every minute
      this.getRoomsInterval = setInterval(() => {
        this.getRooms(null, true)
      }, 1 * 60 * 1000)
    },

    /**
     * Subscribe to global notification channel
     */
    subscribeToGlobalNotificationChannel() {
      const userProfile = JSON.parse(localStorage.getItem('user_profile'));
      if (userProfile && userProfile.userId) {
        const notificationChannel = `user_${userProfile.userId}_notifications`;

        // Only subscribe if we're not already subscribed
        if (!this.chatChannel.includes(notificationChannel)) {
          this.$pnSubscribe({
            channels: [notificationChannel]
          });
          this.$pnGetMessage(notificationChannel, this.handleGlobalNotification);

          // Add to our tracked channels
          this.chatChannel.push(notificationChannel);
        }
      }
    },

    /**
     * Unsubscribe from all channels
     */
    unsubscribeFromChannels() {
      // Unsubscribe from all channels
      if (this.chatChannel && this.chatChannel.length > 0) {
        this.$pnUnsubscribe({
          channels: this.chatChannel
        });
        console.log('Unsubscribed from channels:', this.chatChannel);
        this.chatChannel = [];
      }
    },

    /**
     * Check if sender is current user
     */
    checkSender(senderId) {
      let user = JSON.parse(localStorage.getItem('user_profile'))
      return user.userId === senderId;
    },

    /**
     * Get chat rooms with pagination
     *
     * @param {string} cursor - Pagination cursor
     * @param {boolean} resetList - Whether to reset the list or append to it
     */
    getRooms(cursor = null, resetList = false) {
      this.isLoading = true;

      // If loading more, set isLoadingMore flag instead
      if (cursor && !resetList) {
        this.isLoading = false;
        this.isLoadingMore = true;
      }

      const loading = this.BtnLoading();

      const params = {
        'limit': 10 // Default limit
      };

      // Add search parameter if it exists
      if (this.message) {
        params.search = this.message;
      }

      // Add cursor parameter for pagination if it exists
      if (cursor) {
        params.cursor = cursor;
      }

      // Get the paginated rooms for display
      this.$http.get('getRooms', {params})
          .then((res) => {
            if (!res.data.error) {
              // If we're resetting the list (new search or initial load)
              if (resetList) {
                // Unsubscribe from current channels before subscribing to new ones
                this.unsubscribeFromChannels();

                // Make sure we're subscribed to the global notification channel
                this.subscribeToGlobalNotificationChannel();

                // Set the new customer list
                this.customers = res.data.data.data;

                // Extract visible chat channels
                const visibleChannels = this.customers
                    .map(item => item.channel)
                    .filter(Boolean);

                // Subscribe to visible channels
                for (let channel of visibleChannels) {
                  this.$pnSubscribe({
                    channels: [channel]
                  });
                  this.$pnGetMessage(channel, this.receptor);
                  this.chatChannel.push(channel);
                }
              } else {
                // We're appending to the list - get the new items
                const newCustomers = res.data.data.data;

                // Extract channels from new items
                const newChannels = newCustomers
                    .map(item => item.channel)
                    .filter(Boolean);

                // Subscribe to any channels we're not already subscribed to
                for (let channel of newChannels) {
                  if (!this.chatChannel.includes(channel)) {
                    this.$pnSubscribe({
                      channels: [channel]
                    });
                    this.$pnGetMessage(channel, this.receptor);
                    this.chatChannel.push(channel);
                  }
                }

                // Add the new customers to our list
                this.customers = [...this.customers, ...newCustomers];
              }

              // Store pagination metadata
              this.pagination = res.data.data.pagination;
              this.currentCursor = this.pagination ? this.pagination.next_cursor : null;
            } else {
              this.customers = [];
              this.pagination = null;
              this.currentCursor = null;
            }

            this.isLoading = false;
            this.isLoadingMore = false;
            loading.close();
          })
          .catch((error) => {
            console.error('Error loading rooms:', error);
            this.customers = [];
            this.pagination = null;
            this.currentCursor = null;
            this.isLoading = false;
            this.isLoadingMore = false;
            loading.close();
          });
    },

    /**
     * Load more chat rooms
     */
    loadMore() {
      if (this.pagination && this.pagination.has_more && this.currentCursor && !this.isLoadingMore) {
        console.log('Loading more content...');
        this.getRooms(this.currentCursor);
      }
    },

    /**
     * Scroll to bottom of chat
     */
    scrollToBottom() {
      this.$nextTick(() => {
        const element = document.getElementById("body-chat");
        element.scrollTop = 0;
        element.scrollTop = element.scrollHeight;
      })
    },

    /**
     * Send a message
     */
    sendMessage() {
      if (this.chat !== '') {
        const loading = this.BtnLoading();
        this.isLoading = true;
        this.$http.post('send-message', {
          'message': this.chat,
          'chatroom_id': this.chatId
        }).then((res) => {
          if (!res.data.error) {
            this.messages.push(res.data.data.chatMessage);
            this.chat = ''
          }
          this.scrollToBottom();
          this.isLoading = false;
          loading.close();
        }).catch(() => {
          this.customers = [];
          this.scrollToBottom();
          this.isLoading = false;
          loading.close()
        });
      }
    },

    /**
     * Set default profile image if loading fails
     */
    setDefaultProfileImage(e) {
      e.target.src = this.defaultProfileImage;
    },

    /**
     * Show image modal
     */
    showImage(url) {
      this.imageUrl = url;
      this.show = true;
    },

    /**
     * Fetch message history for a chat room
     */
    fetchHistoryMessage(chatId, type) {
      this.isLoading = true
      const loading = this.BtnLoading();
      this.$http.get('getHistory-message/' + chatId, {
        params: {
          'get_type': type ? 'pubnub' : 'logistic'
        }
      }).then((res) => {
        if (!res.data.error) {
          this.messages = res.data.data;
          this.updateRoom(chatId);
          this.scrollToBottom();
          loading.close()
        } else {
          this.messages = [];
          this.scrollToBottom();
        }
        this.isLoading = false;
        loading.close()
      }).catch(() => {
        this.customers = [];
        this.scrollToBottom();
        this.isLoading = false;
        loading.close()
      });
    },

    /**
     * Select a chat
     */
    selectChat(id, type) {
      this.chatId = id.chatroom_id;
      this.messages = [];
      this.fetchHistoryMessage(this.chatId, type);
    },

    /**
     * Handle notification from global notification channel
     */
    handleGlobalNotification(notification) {
      console.log('Received global notification:', notification);

      // Only process if it's a new message notification
      if (notification.message && notification.message.type === 'new_message') {
        const notificationData = notification.message;
        const chatroomId = notificationData.chatroom_id;

        // Check if this chatroom is in our current list
        const roomInList = this.customers.some(customer =>
            customer.chatroom_id == chatroomId
        );

        // If the user is not currently in this chat room, show notification
        if (this.chatId !== chatroomId) {
          // Show notification with sender and message info
          this.$notification.OpenNotification_New_Message(
              'top-right',
              'primary',
              3000,
              `${notificationData.sender_name}: ${notificationData.message.substring(0, 30)}${notificationData.message.length > 30 ? '...' : ''}`
          );
        }

        // If room is not in the current view, refresh the list
        if (!roomInList) {
          // Wait a short moment to ensure database update is complete
          setTimeout(() => {
            this.getRooms(null, true);
          }, 300);
        } else if (this.chatId === chatroomId) {
          // If we're in the current room, just update the room data without refreshing
          this.updateRoom(chatroomId);
        }
      }
    },

    /**
     * Handle message from a chat channel
     */
    receptor(msg) {
      // Update UI only if the received message is for the current chat room
      if (this.chatId != null && (this.chatId === msg.message.chatroom_id)) {
        this.updateMessage(msg);
      }

      // Handle notification for all messages not from the current user
      let user = JSON.parse(localStorage.getItem('user_profile'));
      if (msg.message.sender_id !== user.userId) {
        this.$notification.OpenNotification_New_Message('top-right', 'primary', 3000);
      }

      // Check if the room of the incoming message is in our current list
      const roomInList = this.customers.some(customer => customer.chatroom_id === msg.message.chatroom_id);

      // If the room isn't in our current list, we need to refresh the list
      if (!roomInList) {
        // Refresh the room list to get the updated order (the room with new message will be at top)
        this.getRooms(null, true);
      } else {
        // Just update the room data if it's already in our list
        this.updateRoom(msg.message.chatroom_id);
      }
    },

    /**
     * Update message in the UI
     */
    updateMessage(msg) {
      const loading = this.BtnLoading();
      if (!this.checkSender(msg.message.sender_id)) {
        this.$http.put('update/chat-message/' + msg.message.id)
        this.messages.push(msg.message)
      }
      this.scrollToBottom()
      loading.close();
    },

    /**
     * Update room data
     */
    updateRoom(roomId) {
      this.isLoading = true
      const loading = this.BtnLoading();
      this.$http.get('update/room/' + roomId, {}).then((res) => {
        if (!res.data.error) {
          this.updateChatRoom = res.data.data;
          this.customers = this.customers.map(item =>
              item.chatroom_id === this.updateChatRoom.chatroom_id
                  ? {
                    ...item,
                    unread: (this.chatId === item.chatroom_id) ? 0 : this.updateChatRoom.unread,
                    latest_message: this.updateChatRoom.latest_message,
                    latest_time: this.updateChatRoom.latest_time
                  }
                  : item
          )
        }
        this.isLoading = false;
        loading.close();
      }).catch(() => {
        this.isLoading = false;
        loading.close();
      });
    },

    /**
     * Open modal for adding an image
     */
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },

    /**
     * Show loading indicator
     */
    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },

    /**
     * Detect URLs in text and make them clickable
     */
    urlDetect(text) {
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
      return text.replace(urlRegex, function (url) {
        return '<a target="_blank" href="' + url + '" style="color:#195BFF">' + url + "</a>";
      });
    },
  },

  watch: {
    chatId(newVal) {
      if (newVal != null) {
        this.scrollToBottom();

        // On mobile, hide sidebar when a chat is selected
        if (this.isMobile) {
          this.showSidebar = false;
        }
      }
    },
    message(value) {
      if (value === '') {
        this.getRooms(null, true);
      }
    }
  }
}
</script>

<style lang="css" scoped>
/* ----------- GENERAL UTILITIES ----------- */
.width-100 {
  width: 100% !important;
}

.main-content-area {
  margin-right: 290px; /* Match the width of the sidebar */
}


/* Container styling */
.container {
  position: relative;
  overflow-x: hidden; /* Prevent horizontal scroll when sidebar slides */
}

/* ----------- INPUT & NAVBAR STYLING (ALL SCREENS) ----------- */
.navbar.is-fixed-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Reset navbar brand margins */
.navbar-brand {
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  width: auto !important;
}

/* Message input container */
.navbar-brand.message-input-container {
  width: 70% !important; /* Control width to match content area */
  max-width: 1000px;
  margin: 0 auto !important;
  left: 0 !important;
  right: 0 !important;
  position: relative !important;
  justify-content: center !important;
  display: flex !important;
}

/* Message field container */
.field.navbar-item.message-field {
  flex-grow: 1 !important;
  max-width: 85% !important; /* Wide on desktop */
  position: relative !important;
  margin: 0 !important;
}

/* Text input styling */
.message-input {
  padding: 14px 20px !important;
  border: 1px solid #EB1D27 !important;
  border-radius: 30px !important;
  width: 1000px !important;
  height: auto !important;
  min-height: 55px !important;
  font-size: 16px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
  padding-right: 45px !important; /* Space for send button */
}

/* Send button styling */
.send-btn {
  right: 15px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  cursor: pointer;
  font-size: 18px;
  z-index: 10;
  opacity: 0.8;
  position: absolute;
}

.sidebar-scrollable-content {
  height: calc(100% - 60px);
  overflow-y: auto;
  position: relative;
  padding-bottom: 80px; /* Space for the button */
}

.send-btn:hover {
  opacity: 1;
}

/* Image button styling */
.send-image-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 46px !important;
  height: 46px !important;
  margin-right: 10px !important;
  margin-top: 10px !important;
  background-color: rgba(235, 29, 39, 0.05);
  border-radius: 50%;
  transition: background-color 0.2s;
  cursor: pointer;
  flex-shrink: 0 !important;
  position: relative;
  z-index: 10000 !important;
}

.send-image-btn:hover {
  background-color: rgba(235, 29, 39, 0.15);
}

.load-more-container-fixed {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
  border-top: 1px solid #f5f5f5;
  z-index: 1050;
}

.send-image-btn i {
  font-size: 22px;
  color: #EB1D27 !important;
}

/* ----------- SIDEBAR STYLING ----------- */
.sidebar-responsive {
  z-index: 1040 !important;
}

.vs-sidebar__item {
  margin-bottom: 8px !important;
  padding-left: 2px !important;
}

.vs-sidebar__item__text {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.active {
  color: #EB1D27 !important;
}

.vs-sidebar__item:after {
  background: #EB1D27 !important;
  padding-right: 8px !important;
}

.vs-sidebar__item.hasIcon.active {
  padding-left: 14px;
}

/* ----------- CHAT MESSAGES STYLING ----------- */
.card {
  box-shadow: none;
}

.scrollable {
  overflow: hidden;
  overflow-y: scroll;
  height: 100vh;
}

/* Message bubbles */
.is-receiver {
  align-items: start;
  justify-content: start;
  text-align: left;
  margin: 10px 200px 10px 0;
}

.is-receiver-background {
  width: fit-content;
  padding: 15px;
  border-radius: 50px 50px 50px 0;
  background-color: #eee;
  margin-bottom: 4px;
}

.is-sender {
  float: right;
  margin: 10px 0px 10px 200px;
  text-align: end;
  display: inline-block;
}

.is-sender-background {
  display: inline-block;
  align-items: end;
  justify-content: end;
  text-align: end;
  padding: 15px;
  width: fit-content;
  border-radius: 50px 50px 0px 50px;
  background-color: #EAFBFF;
  margin-bottom: 4px;
}

/* Image messages */
.is-receiver-file {
  align-items: start;
  justify-content: start;
  text-align: left;
}

.is-sender-file {
  align-items: end;
  justify-content: end;
  text-align: right;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  max-height: 320px;
}

/* Chat timestamps */
.chat-date {
  font-size: 12px;
  font-weight: 400;
}

.chat-date-file {
  font-size: 12px;
  font-weight: 400;
  align-items: start;
  justify-content: start;
  text-align: left;
}

.chat-date-file-sender {
  font-size: 12px;
  font-weight: 400;
  align-items: end;
  justify-content: end;
  text-align: right;
}

/* ----------- SIDEBAR CHAT LIST ----------- */
.chat-list {
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-top: auto;
  padding: 2px 18px 2px 0px;
  margin-bottom: auto;
  display: flex;
}

.chat-list:hover {
  transition: 0.4ms;
  opacity: 0.8;
  cursor: pointer;
}

.text-message {
  width: 70%;
  text-align: left;
  padding-left: 20px;
}

.profile {
  width: 30%;
}

.image.chat {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.chat-name {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 600;
}

.highlight-message {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px !important;
}

.sidebar-name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-tel {
  font-size: 12px;
}

.sidebar-message {
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
}

/* Load More Button Container */
.load-more-container {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  margin-top: 10px;
}

/* Load More Button */
.load-more-button {
  background-color: #fff;
  color: #EB1D27;
  border: 1.5px solid #EB1D27;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  margin-bottom: 100px;
  min-height: 40px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.load-more-button:hover {
  background-color: rgba(235, 29, 39, 0.05);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);}

.load-more-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Loading spinner */
.loading-spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #EB1D27;
  animation: spin 1s ease-in-out infinite;
  margin: 0 auto;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* ----------- UTILITY CLASSES ----------- */
.text-center {
  text-align: center;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.grey-out {
  color: lightgrey;
}

/* URL styling */
a {
  color: #195BFF;
  word-break: break-word;
  text-decoration: underline;
}

/* ----------- EXTRA LARGE SCREENS ----------- */
@media screen and (min-width: 1400px) {
  .navbar-brand.message-input-container {
    max-width: 1100px; /* Even wider for extra large screens */
  }

  .field.navbar-item.message-field {
    max-width: 90% !important; /* Wider input field on very large screens */
  }
}

/* ----------- MOBILE STYLING ----------- */
@media screen and (max-width: 768px) {
  /* Mobile sidebar */
  .sidebar-hidden .vs-sidebar {
    transform: translate(100%, 0) !important;
    width: 100% !important;
    position: fixed !important;
    transition: transform 0.3s ease-in-out;
  }

  .sidebar-visible .vs-sidebar {
    transform: translate(0, 0) !important;
    width: 100% !important;
    position: fixed !important;
    top: 52px !important;
    transition: transform 0.3s ease-in-out;
  }

  .vs-sidebar {
    z-index: 1040 !important;
  }

  /* Mobile toggle button */
  .sidebar-toggle {
    position: fixed !important;
    right: 15px;
    top: 65px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #EB1D27;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1050 !important;
    cursor: pointer;
    transition: transform 0.3s;
  }

  .sidebar-toggle i {
    right: 15px !important;
    top: 15px !important; /* Move higher up to avoid overlap */
    z-index: 1050 !important;  }

  /* Mobile message bubbles */
  .is-receiver {
    margin: 10px 60px 10px 0;
  }

  .is-sender {
    margin: 10px 0px 10px 60px;
  }

  .main-content-area {
    margin-right: 0;
  }

  .sidebar-responsive {
    width: 280px !important; /* Fixed width */
    max-width: 280px !important;
  }

  .sidebar-scrollable-content {
    height: calc(100% - 60px);
    overflow-y: auto;
    position: relative;
    padding-bottom: 80px; /* Space for the button */
  }

  .load-more-container-fixed {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    border-top: 1px solid #f5f5f5;
    z-index: 1050;
  }

  .load-more-button {
    background-color: #fff;
    color: #EB1D27;
    border: 1.5px solid #EB1D27;
    border-radius: 20px;
    padding: 8px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 130px;
    min-width: 120px;
    min-height: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .load-more-button:hover {
    background-color: rgba(235, 29, 39, 0.05);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  }

  .bottom-padding {
    height: 80px;
  }

  .is-receiver-background, .is-sender-background {
    padding: 10px;
    border-radius: 20px 20px 20px 0;
    max-width: 100%;
  }

  .is-sender-background {
    border-radius: 20px 20px 0px 20px;
  }

  /* Mobile input adjustment */
  .navbar-brand.message-input-container {
    width: 85% !important;
    padding: 5px !important;
  }

  .field.navbar-item.message-field {
    max-width: 75% !important;
    margin: 0 !important;
  }

  .send-image-btn {
    margin-left: 20px !important;
    margin-top: 8px !important;
    margin-right: -5px !important;
    z-index: 1000 !important;
    position: relative !important;
  }

  .message-input {
    padding: 12px 35px 12px 15px !important; /* Adjusted padding */
    min-height: 50px !important;
    width: 100% !important;
  }

  .navbar.is-fixed-bottom {
    min-height: 70px !important;
    padding: 5px 0;
  }

  .chat-date {
    font-size: 10px;
  }
}
</style>
